::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 0.5rem;
}
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
::-webkit-scrollbar-track {
  width: 0.5rem;
  height: 0.5rem;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
